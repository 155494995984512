const Footer = () => {
  return (
    <div className='mt-12 bg-primary'>
       {/* <hr className=' border-1 border-hint' /> */}
       <p className='text-center py-7 text-white'>
        BettaDesign 2024, All Right Reserved
       </p>
    </div>
  )
}

export default Footer