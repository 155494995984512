import React from 'react';
import Nav from './components/Nav';
import Hero from './components/Hero';
import Features from './components/Features';
import MyPortfolio from './components/Portfolio';
import Skills from './components/Skills';
import Contact from './components/Contact';
import Footer from './components/Footer';
import FloatingButton from './components/FloatingButton';

function App() {
  return (
    <div>
     <Nav/>
     <Hero/>
     <Features/>
     <MyPortfolio/>
     <Skills/>
     <FloatingButton/>
     <Contact/>
     <Footer/>
    </div>
  );
}

export default App;
